import apiClient from '@/client';

export default {
  post: {
    reviews: (account, mood, message) => {
      return apiClient.post(`/public/accounts/${account}/reviews`, {
        mood,
        message
      });
    }
  }
};