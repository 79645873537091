<template>
  <div class="feedback-layout">
    <transition name="fade-faster">
      <div v-if="!loading" class="feedback-card">
        <div v-if="shopInfo.logo" class="card-header">
          <div class="card-logo-wrapper">
            <img :src="shopInfo.logo" alt="shop-logo" />
          </div>
        </div>

        <transition name="fade-fastest" mode="out-in">
          <div
            v-if="showThankyouMessage"
            key="thankyou_wrapper"
            class="thank-you-message-wrapper"
          >
            <div class="icon-wrapper">
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.8512 15.924C30.5346 15.2405 30.5346 14.1325 29.8512 13.4491C29.1678 12.7657 28.0598 12.7657 27.3764 13.4491L17.6762 23.1492L13.942 19.415C13.2586 18.7316 12.1506 18.7316 11.4672 19.415C10.7837 20.0984 10.7837 21.2065 11.4672 21.8899L16.4388 26.8615C17.1222 27.5449 18.2302 27.5449 18.9137 26.8615L29.8512 15.924Z"
                  fill="#29A341"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.6592 2.5C10.9063 2.5 3 10.4063 3 20.1592C3 29.9121 10.9063 37.8184 20.6592 37.8184C30.4121 37.8184 38.3184 29.9121 38.3184 20.1592C38.3184 10.4063 30.4121 2.5 20.6592 2.5ZM6.5 20.1592C6.5 12.3393 12.8393 6 20.6592 6C28.4791 6 34.8184 12.3393 34.8184 20.1592C34.8184 27.9791 28.4791 34.3184 20.6592 34.3184C12.8393 34.3184 6.5 27.9791 6.5 20.1592Z"
                  fill="#29A341"
                />
              </svg>
            </div>
            <div class="wrapper-title">Thank you!</div>
            <div class="wrapper-text">
              We'll share your feedback with the shop so they can improve these
              issues in the future.
            </div>
          </div>

          <div
            v-else-if="showBadRatingView"
            key="leave_bad_feedback_screen"
            class="card-body bad-feedback-screen"
          >
            <div class="card-title">
              We're sorry you had a bad experience, <br />
              thanks for your feedback!
            </div>

            <div class="bad-feedback-text-wrapper">
              <div class="card-text accented">
                What issues did you encounter?
              </div>
            </div>

            <div class="bad-rating-reasons-wrapper">
              <div
                class="bad-rating-reason-box"
                v-for="(reason, idx) in badRatingReasons"
                :key="idx"
                @click="() => handleSelectBadRatingReason(reason)"
                :class="{
                  selected: selectedBadRatingReason === reason,
                }"
              >
                {{ reason }}
              </div>
            </div>

            <transition name="fade-faster">
              <div
                v-if="selectedBadRatingReason === 'Other'"
                class="feedback-input-wrapper"
              >
                <el-input
                  type="textarea"
                  placeholder="Let us know how we can improve in the future"
                  rows="5"
                  v-model="feedback"
                >
                </el-input>
              </div>
            </transition>

            <div
              v-if="!showThankyouMessage && showBadRatingView"
              class="action-buttons-wrapper"
            >
              <div class="inner-wrapper">
                <el-button
                  v-if="showBadRatingView"
                  @click.prevent="handleBadRatingBackBtnClick"
                  type="secondary"
                >
                  Back
                </el-button>

                <el-button
                  @click.prevent="sendFeedback"
                  type="primary"
                  :disabled="isSendButtonDisabled || sendingReview"
                >
                  Submit
                </el-button>
              </div>
            </div>
          </div>

          <div v-else key="leave_feedback_screen" class="card-body">
            <div v-if="customerName" class="title-with-icon-wrapper">
              <div class="card-title">
                Hey {{ customerName }}, how did we do?
              </div>
            </div>

            <el-row :gutter="20">
              <el-col
                v-for="(availableRating, idx) in availableRatings"
                :key="idx"
                :span="6"
              >
                <div
                  class="rating-card"
                  :class="{
                    selected: selectedEvaluation === availableRating.evaluation,
                  }"
                  @click="evaluateShop(availableRating.evaluation)"
                >
                  <div class="rating-icon-wrapper">
                    <img :src="availableRating.icon" alt="rating-icon" />
                  </div>
                  <div class="rating-text">
                    {{ availableRating.evaluation }}
                  </div>
                </div>
              </el-col>
            </el-row>

            <transition name="fade-faster">
              <div v-if="showFeedbackInput" class="feedback-input-wrapper">
                <el-input
                  type="textarea"
                  placeholder="Let us know how we can improve in the future"
                  rows="5"
                  v-model="feedback"
                />
                <div class="action-buttons-wrapper">
                  <div class="inner-wrapper">
                    <el-button
                      @click.prevent="sendFeedback"
                      type="primary"
                      :disabled="isSendButtonDisabled || sendingReview"
                    >
                      Submit
                    </el-button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import ShopServicesService from "@/services/ShopServicesService";
import ReviewsService from "@/services/ReviewsService";

export default {
  name: "Feedback",
  data() {
    return {
      loading: true,
      shopInfo: null,

      availableRatings: [
        {
          icon: "/img/bad-rating-icon.png",
          evaluation: "Bad",
        },
        {
          icon: "/img/okay-rating-icon.png",
          evaluation: "Okay",
        },
        {
          icon: "/img/good-rating-icon.png",
          evaluation: "Good",
        },
        {
          icon: "/img/awesome-rating-icon.png",
          evaluation: "Awesome",
        },
      ],
      badRatingReasons: [
        "Too expensive",
        "Staff's attitude",
        "Took too long",
        "Unprofessional",
        "Other",
      ],
      selectedBadRatingReason: "",
      selectedEvaluation: "",
      showFeedbackInput: false,
      showBadRatingView: false,
      feedback: "",

      sendingReview: false,
      showThankyouMessage: false,
    };
  },
  mounted() {
    this.setFeedbackGlobalBackgroundColor();

    this.fetchAccountData();
  },
  methods: {
    async fetchAccountData() {
      const { shop } = this.$route.query;
      if (!shop) return;

      const {
        data: { data },
      } = await ShopServicesService.get.shopInfo(shop);

      this.loading = false;
      this.shopInfo = data;
    },
    setFeedbackGlobalBackgroundColor() {
      document.querySelector("body").style.backgroundColor = "#F9F9FB";
      document.querySelector(".app-container").style.backgroundColor =
        "#F9F9FB";
    },
    evaluateShop(evaluation) {
      this.selectedEvaluation = evaluation;

      if (evaluation === "Bad") {
        this.showBadRatingView = true;
        this.feedback = "";
        return;
      }

      this.showFeedbackInput = true;
    },
    handleSelectBadRatingReason(reason) {
      this.selectedBadRatingReason = reason;
      if (!this.feedback) return;
      this.feedback = "";
    },
    handleBadRatingBackBtnClick() {
      this.showBadRatingView = false;
      this.feedback = "";
      this.selectedBadRatingReason = "";
      this.selectedEvaluation = "";
      this.showFeedbackInput = false;
    },
    async sendFeedback() {
      this.sendingReview = true;
      const { shop } = this.$route.query;
      const message =
        this.showBadRatingView &&
        this.selectedBadRatingReason.toLowerCase() !== "other"
          ? this.selectedBadRatingReason
          : this.feedback;

      await ReviewsService.post.reviews(
        shop,
        this.selectedEvaluation.toLowerCase(),
        message
      );

      if (this.selectedEvaluation.toLowerCase() !== "bad") {
        window.location.href = this.shopInfo.gmb_review_link;
        return;
      }

      this.sendingReview = false;
      this.showThankyouMessage = true;
    },
  },
  computed: {
    customerName() {
      return this.$route.query.customer_name || null;
    },
    isSendButtonDisabled() {
      if (!this.showBadRatingView && !this.feedback) return true;

      if (this.showBadRatingView && !this.selectedBadRatingReason) return true;

      if (
        this.showBadRatingView &&
        this.selectedBadRatingReason === "Other" &&
        !this.feedback
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
